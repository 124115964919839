<style lang="less" scoped>
	.content {
		text-align: left;
		background: #ffffff;

		.head {
			background-repeat: no-repeat;
			background-size: cover;
			height: 560px;
			text-align: left;
			padding: 322px 0 0 0;

			.title {
				width: 1360px;
				margin: 0 auto;
				font-size: 58px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}
			.txt {
				width: 1360px;
				margin: 0 auto;
				font-size: 35px;
				padding-top: 20px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}
		}
		.expertSthink_middle {
			padding-bottom: 67px;
			width: 1360px;
			margin: 0 auto;
			flex-wrap: wrap;
			justify-content: flex-start;
			.expertSthink_box {
				width: 25%;
				padding-top: 30px;
				.flex_box:hover {
					box-shadow: 0px 0px 5px 5px #dcdcdc;
				}

				.flex_box {
					padding-top: 10px;
					// height: 528px;
					img {
						object-fit: cover;
					}
					.box_img {
						width: 320px;
						height: 390px;
						margin: 0 auto;
					}
				}

				.box_characters {
					text-align: left;
					width: 320px;
					margin: 0 auto;

					.box_name {
						font-size: 25px;
						font-weight: bold;
						padding: 16px 0;
					}

					.box_position {
						// height: 50px;
						font-size: 16px;
						padding-bottom: 10px;
					}
				}
			}
		}

		.dialog {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.3);
			z-index: 10000;
			display: flex;
			justify-content: center;
			align-items: center;
			animation-name: scale-up-center;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;

			@keyframes scale-up-center {
				0% {
					-webkit-transform: scale(.2);
					transform: scale(.2);
				}

				100% {
					-webkit-transform: scale(1);
					transform: scale(1);
				}
			}

			.box {
				width: 804px;
				background-color: white;
				text-align: left;
				position: relative;

				.close {
					width: 20px;
					height: 20px;
					position: absolute;
					right: -37px;
					top: -50px;
					cursor: pointer;
					img{
						width: 50px;
						height: 50px;
					}
				}

				.head {
					background: url(../../../assets/expertSthinkbg.png);
					background-size: cover;
					font-size: 45px;
					height: 262px;
					padding: 80px 0 0 62px;
					position: relative;

					.time {}

					.name {}

					.position {
						font-size: 18px;
						padding-top: 21px;
						width: 500px;
					}

					.img {
						position: absolute;
						right: 0;
						top: 0;
						width: 215px;
						height: 262px;
						
						img{
							object-fit: cover;
						}
					}

				}

				.foot {
					padding: 56px 62px 52px 62px;
					font-size: 22px;
					background: url(../../../assets/experIcon.png) no-repeat;
					background-size: cover;
					height: 324px;

					div {
						padding-bottom: 15px;
					}
				}
			}
		}
	}

	/deep/ .el-form-item__content {
		text-align: initial;
		line-height: 58px;
	}

	/deep/ .el-cascader {
		width: 460px;
	}

	/deep/ .el-input__inner {
		height: 58px;
		font-size: 22px;
	}

	.date {
		width: 1360px;
		margin: 50px auto;
		margin-bottom:20px;

		.input {
			width: 350px;
		}
	}

	.type {
		height: 100%;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		font-weight: bold;
	}
</style>
<template>
	<div class="content">
		<!-- <div class="expertSthink_head" v-if="image" :style="{background: 'url('+ image +')'}" style="background-repeat: no-repeat;background-size: cover;">
			<div class="expertSthink_head_conten">
				<p class="title">{{$t('expertSthink.title')}}</p>
				<p class="characters">{{$t('expertSthink.text')}}</p>
			</div>
		</div> -->
		<div class="head" v-if="image" :style="{background: 'url('+ image +')'}" style="background-repeat: no-repeat;background-size: cover;">
			<div class="title">{{$t('expertSthink.title')}}</div>
			<div class="txt">{{$t('expertSthink.text')}}</div>
		</div>
		<div class="date">
			<el-select v-model="date" :placeholder="$t('newText.msg8')" class="input"
				@change="expertSthinkList">
				<el-option v-for="item in options" :key="item.year" :label="item.year"
					:value="item.year">
				</el-option>
			</el-select>
		</div>
		<div class="expertSthink_middle flex_just_disp">
			<div class="expertSthink_box cursorS" v-for="(item,index) in contentList" :key="index">
				<div class="flex_box " @click="showDialog(item)">
					<div class="icon_image box_img">
						<!-- <img :src="item.avatar" /> -->
						<image-Obscuration :image="item.avatar" :obscuration="true" :titleShow="false" :larger="false">
							<template v-slot:content>
								<div class="type">{{$t('expertSthink.gengduo')}}</div>
							</template>
						</image-Obscuration>
					</div>
					<div class="box_characters">
						<div class="box_name">{{item.en_name}}</div>
						<div class="box_position text_2_size">{{item.en_position}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dialog" @click.stop="dialogTableVisible=false" v-show="dialogTableVisible">
			<div class="box" @click.stop="">
				<div class="head">
					<!-- <div class="time">{{obj.year}}</div> -->
					<div class="name">{{obj.en_name}}</div>
					<div class="position">{{obj.en_position}}</div>
					<div class="img icon_image">
						<img :src="obj.avatar" />
					</div>
				</div>
				<div class="foot">
					<div v-html="obj.en_text"></div>
				</div>
				<div class="close icon-image" @click.stop="dialogTableVisible=false"><img
						src="../../../assets/imgdele.png"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import imageObscuration from '../../../components/imageObscuration'
	export default {
		components: {
			imageObscuration
		},
		data() {
			return {
				dialogTableVisible: false,
				contentList: [],
				obj: {},
				date: "",
				image: "",
				options:[]
			}
		},
		mounted() {
			this.expertSthinkList()
			this.imageList()
			this.yearList()
		},
		methods: {
			async yearList() {
				try {
					let _this = this
					let params = {
						// position_id: 5
					}
					let res = await this.$axios.post('index/getYear', {
						params: params
					});
					if (res.code == 1) {
						this.options=res.data
					}
				} catch (error) {
					console.log(error);
				}
			},
			async imageList() {
				try {
					let _this = this
					let params = {
						position_id: 5
					}
					let res = await this.$axios.get('ads/getList', {
						params: params
					});
					if (res.code == 1) {
						this.image = 'https://cultural-creation.oss-cn-beijing.aliyuncs.com/' + res.data[0].image
					}
				} catch (error) {
					console.log(error);
				}
			},
			async expertSthinkList() {
				try {
					let _this = this
					let params = {
						page: 1,
						year: this.date
					}
					let res = await this.$axios.get('index/expert', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.contentList = res.data
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
			showDialog(val) {
				this.obj = val
				this.dialogTableVisible = true
			}
		}
	}
</script>
